<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="row mb-2">
        <div class="col-md-6">
          <div
            class="anan-fyt-tab"
            :class="{ active: isActive === '0' }"
            @click="getData(), isActive = '0'"
          >
            <span class="mb-25"><i class="fal fa-university" /> {{ $t('DepositTaobao') }} </span>
          </div>
        </div>

        <div class="col-md-6">
          <div
            class="anan-fyt-tab"
            :class="{ active: isActive === '1' }"
            @click="GetOrderAll(), isActive = '1'"
          >
            <span class="mb-25"><i class="fas fa-history" /> {{ $t('shopRefunds') }} </span>
          </div>
        </div>

      </div>

      <div
        v-if="isActive === '0'"
        class="card"
      >

        <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
          <h3 class="font-weight-bolder">
            {{ $t('DepositTaobao') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @change="getData(status, search)"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <b-form-select
              v-model="taobaoStatus"
              :options="options"
              class="w-50 ml-1"
              size="sm"
              @change="getData(status = taobaoStatus, search)"
            />
          </div>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pl-2 pr-2 pb-2">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="itemsTaobao"
              :fields="fieldsTaobao"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner
                    label="กำลังโหลด..."
                    variant="danger"
                  />
                </div>
              </template>

              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(number)="data">
                <!-- {{ data.item.itemnumber_text }} -->
                <b-link :to="{ name: 'admin-china-payfor-check', params: { id: data.item._id } }">
                  {{ data.item.itemnumber_text }}
                </b-link>
              </template>

              <template #cell(customer)="data">
                <b-link :to="{ name: 'admin-employee-edit', params: { id: data.item.uid } }">
                  {{ data.item.username }}
                </b-link>

              </template>

              <template #cell(transactionDate)="data">
                {{ time(data.item.created_at) }}
              </template>

              <template #cell(keyword)="data">
                {{ data.item.keyword_admin ? data.item.keyword_admin : '-' }}
              </template>

              <template #cell(type)="data">
                <span>
                  <!-- {{ $t('key-57') }}  -->
                  {{ data.item.payment_type.type_name }}
                </span>
                <!-- <span v-else>
                  {{ $t('key-58') }} {{ data.item.payment_type.type_name }}
                </span> -->
              </template>

              <template #cell(status)="data">
                <span
                  v-if="data.item.status === 'waiting'"
                  class="badge badge-primary badge-pill"
                > {{
                  $t('pendingReview')
                }} </span>

                <span
                  v-if="data.item.status === 'success'"
                  class="badge badge-success badge-pill"
                > {{
                  $t('operationCompleted') }} </span>
              </template>
              <template #cell(yuanPaid)="data">
                <div
                  v-for="(item, index) in data.item.products"
                  :key="index"
                >
                  <span v-if="item.status == 1"><i
                    class="fas fa-history text-primary"
                    style="font-size: 12px;"
                  />
                  </span>
                  <span v-if="item.status == 2"><i
                    class="fas fa-check text-success"
                    style="font-size: 12px;"
                  /></span>
                  <span v-if="item.status == 0"><i
                    class="fas fa-ban text-danger"
                    style="font-size: 12px;"
                  /></span>
                  {{ Commas(item.price) }} ¥ <br>
                </div>
              </template>
              <template #cell(moneySystem)="data">
                {{ Commas(data.item.pay_products.toFixed(2)) }}
              </template>
              <template #cell(yuan)="data">
                ¥ {{ data.item.apply_yuan ? Commas(data.item.apply_yuan) : 0 }}
              </template>

              <template #cell(action)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="$router.push({ name: 'admin-china-payfor-check', params: { id: data.item._id } })"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData(status, search)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>

      <div
        v-if="isActive === '1'"
        class="card"
      >
        <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
          <h3 class="font-weight-bolder">
            {{ $t('shopRefunds') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search_val"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @change="GetOrderAll(status, search_val)"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <b-form-select
              v-model="refundStatus"
              :options="options"
              class="w-50 ml-1"
              size="sm"
              @change="GetOrderAll(status = refundStatus, search_val)"
            />
          </div>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pb-2 pr-2 pl-2">
            <b-table
              striped
              hover
              responsive
              :per-page="perPages"
              :items="itemsCashback"
              :fields="fieldsCashback"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner
                    label="กำลังโหลด..."
                    variant="danger"
                  />
                </div>
              </template>

              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(number)="data">
                <b-link :to="{ name: 'admin-china-payfor-refund-edit', params: { id: data.item._id } }">
                  {{ data.item.itemnumber_text }}
                </b-link>
              </template>
              <template #cell(customer)="data">
                <b-link :to="{ name: 'admin-employee-edit', params: { id: data.item.uid } }">
                  {{ data.item.username }}
                </b-link>
              </template>
              <template #cell(transactionDate)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(keyword)="data">
                {{ data.item.keyword_admin ? data.item.keyword_admin : '-' }}
              </template>
              <template #cell(tao_itemnumber_text)="data">
              <b-link
                v-if="data.item.tao_id"
                :to="{
                  name: 'admin-china-payfor-check',
                  params: { id: data.item.tao_id },
                }"
              >
                {{ data.item.tao_itemnumber_text }}
              </b-link>
              <span v-else>-</span>
              
            </template>

              <template #cell(amount)="data">
                {{ Commas(data.item.products.reduce((total, acc) => total + (acc.status == 2 ? acc.price_ban : 0), 0)) }} ฿
              </template>
              <template #cell(cashback)="data">
                <div
                  v-for="(item, index) in data.item.products"
                  :key="index"
                >
                  <span v-if="item.status == 1"><i
                    class="fas fa-history text-primary"
                    style="font-size: 12px;"
                  />
                  </span>
                  <span v-if="item.status == 2"><i
                    class="fas fa-check text-success"
                    style="font-size: 12px;"
                  /></span>
                  <span v-if="item.status == 0"><i
                    class="fas fa-ban text-danger"
                    style="font-size: 12px;"
                  /></span>
                  ¥ {{ Commas(item.price) }} <br>
                </div>
              </template>
              <template #cell(action)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="$router.push({ name: 'admin-china-payfor-refund-edit', params: { id: data.item._id } })"
                />
              </template>

            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end">
              <b-pagination
                v-model="currentPages"
                :total-rows="totalRows"
                :per-page="perPages"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="GetOrderAll(status, search_val)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>

    </div>
  </validation-observer>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BTable, BSpinner, BFormSelect, BOverlay,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
// import vSelect from 'vue-select'

export default {
  components: {
    BPagination,
    BLink,
    BTable,
    BSpinner,
    BFormSelect,
    // BFormTextarea,
    // ValidationProvider,
    // ValidationObserver,
    // vSelect,
    // BFormGroup,
    // BFormInput,
    BOverlay,
  },
  data() {
    return {
      // required,
      selected: null,
      search: null,
      id: null,
      amount: 0,
      detail: '',
      type: null,
      username_cus: '',
      prefix: '',
      prefixnumber: '',
      isActive: '0',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      currentPages: 1,
      perPages: 10,
      totalRows: 1,

      // Options
      taobaoStatus: null,

      refundStatus: null,
      search_val: null,
      typeOp: [
        { value: 1, text: 'เติมเงิน' },
        { value: 2, text: 'ถอนเงิน' },
        { value: 3, text: 'ชำระค่านำเข้า' },
        { value: 4, text: 'ค่าสินค้า' },
        { value: 5, text: 'ส่วนต่างค่าขนส่งในไทย' },
        { value: 6, text: 'ค่าสินค้าใน MALL' },
        { value: 7, text: 'ฝากจ่ายเถาเปา' },
        { value: 8, text: 'ค่า Commission' },
        { value: 9, text: 'เติมเงินอาลีเพย์' },
        { value: 10, text: 'โอนเงินไปจีน' },
        { value: 11, text: 'แจ้งไม่ได้รับสินค้า' },
        { value: 12, text: 'แจ้งร้านค้าคืนเงิน' },
        { value: 13, text: 'ค่าจัดส่งในไทย' },
        { value: 14, text: 'แลกเงินหยวนเป็นเงินบาท' },
        { value: 15, text: 'ส่งของไปจีน' },
        { value: 16, text: 'แลกแต้ม' },
        // { value: 17, text: 'อื่นๆ' },
      ],

      // Table Taobao

      itemsTaobao: [],

      // Table Cashback

      itemsCashback: [],

      // Table Yuan

      itemsYuan: [],

      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      showOver: false,
    }
  },
  computed: {
    options() {
      return [
        { value: null, text: this.$t('all') },
        { value: 1, text: this.$t('cancel') },
        { value: 2, text: this.$t('pendingReview') },
        { value: 3, text: this.$t('operationCompleted') },
      ]
    },
    fieldsTaobao() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'transactionDate', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'keyword', label: this.$t('mnemonicPhrase'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type', label: this.$t('type'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'yuanPaid', label: this.$t('yuanAmountDue'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'moneySystem', label: this.$t('useSystemBalance'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        // {
        //   key: 'yuan', label: 'ใช้ยอดเงินหยวน', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    fieldsCashback() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'transactionDate', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'tao_itemnumber_text', label: this.$t('payTaobao'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'keyword', label: this.$t('mnemonicPhrase'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'cashback', label: this.$t('refundList'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('key-59'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    fieldsYuan() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'transactionDate', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'listMaker', label: this.$t('transactionMaker'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type', label: this.$t('type'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'remaining', label: this.$t('remainingBalance'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    this.GetNotify()
    // this.GetOrderAll(this.status)
    // this.getData(this.status)
    this.getBalance()
    this.handleQueryChange()
    // this.getCuslist()
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 1) {
          this.isActive = '0'
          this.taobaoStatus = 2
          this.getData(this.status = this.taobaoStatus)
        }
        if (Number(querykey) === 2) {
          this.isActive = '1'
          this.refundStatus = 2
          this.GetOrderAll(this.status = this.refundStatus)
        }
      } else {
        this.getData(this.status)
      }
    },
    // submit() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       const index = this.typeOp.findIndex(x => x.value === this.type)
    //       const params = {
    //         _id: this.id,
    //         username_cus: this.username_cus,
    //         type_text: this.typeOp[index].text,
    //         type: this.type,
    //         detail: this.detail,
    //         amount: this.amount,
    //         prefix: this.prefix,
    //         prefixnumber: this.prefixnumber,
    //       }
    //       this.$http.post('transactions/storeAndupdate', params)
    //         .then(() => {
    //           this.getData(this.isActive)
    //           this.id = null
    //           this.username_cus = null
    //           this.type = null
    //           this.detail = null
    //           this.amount = 0
    //           this.prefix = null
    //           this.prefixnumber = null
    //           this.Success()
    //         })
    //         .catch(error => {
    //           this.SwalError(error.response.data.message)
    //         })
    //       this.$bvModal.hide('modal-add-new')
    //     }
    //   })
    // },
    showAddNew() {
      this.id = null
      this.username_cus = null
      this.type = null
      this.detail = null
      this.amount = null
      this.prefix = null
      this.prefixnumber = null
      this.$bvModal.show('modal-add-new')
    },
    showEdit(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.id = data._id
      this.username_cus = data.username_cus
      this.type = data.type
      this.detail = data.detail
      this.amount = data.amount
      this.prefix = data.prefix
      this.prefixnumber = data.prefixnumber
      this.$bvModal.show('modal-add-new')
    },
    async getBalance() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type: this.isActive,
        search: this.search,
      }
      this.$http
        .get('/Payforchina/getBalance', params)
        .then(response => {
          this.itemsYuan = response.data
          // console.log(this.itemsYuan)
          // console.log(this.items)
          // this.totalRows = response.data.total
          this.show = false
        })
        .catch(error => console.log(error))
      //
    },
    getData(type) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
        search: this.search,
      }
      this.$http
        .get('/Payforchina/getPaymentAdmin', { params })
        .then(response => {
          this.itemsTaobao = response.data.data
          // console.log(this.items)
          this.totalRows = response.data.total
          this.showOver = false
        })
        .catch(error => {
          console.log(error)
          this.SwalError(error)
          this.showOver = false
        })
    },
    // async getCuslist() {
    //   this.$http
    //     .get('/users/cuslist')
    //     .then(response => {
    //       this.cuslist = response.data
    //       console.log(this.cuslist)
    //     })
    //     .catch(error => console.log(error))
    // },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    GetOrderAll(type) {
      this.showOver = true
      const params = {
        page: this.currentPages,
        perPage: this.perPages,
        type,
        search_val: this.search_val,
      }
      this.$http
        .get('/Payforchina/getData/RefundAdmin', { params })
        .then(response => {
          // console.log(response.data)
          this.itemsCashback = response.data.data

          // this.totalRows = this.items.length
          this.totalRows = response.data.total
          this.showOver = false
          // console.log(response.data.total)
        })
        .catch(error => {
          console.log(error)
          this.SwalError(error)
          this.showOver = false
        })
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(amount)) {
          return { color: 'green' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'red' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(value)) {
          return '+'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '-'
        }
      }
      return ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
